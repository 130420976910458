<template>
  <div v-if="!loading">
    <go-back :title="$t('menu.my-organization')"/>
    <b-row>
      <b-col cols="9">
        <b-card>
          <b-row>
            <!-- User Info: Left col -->
            <b-col
              cols="12"
              md="6"
              class="d-flex justify-content-between flex-column"
            >
              <!-- User Avatar & Action Buttons -->
              <div class="d-flex justify-content-start">
                <b-avatar
                  :src="
                    organization.avatar ? organization.avatar.thumbnail : null
                  "
                  :text="organization.name | avatarText"
                  size="104px"
                  rounded
                />
                <div class="d-flex flex-column ml-1">
                  <div class="mb-1">
                    <h4 class="mb-0">{{ organization.name }}</h4>
                    <span class="card-text">{{ organization.email }}</span>
                  </div>
                  <div
                    class="d-flex flex-wrap"
                    style="gap: 1rem"
                    v-if="$auth.check('organization-update')"
                  >
                    <b-button
                      :to="{
                        name: 'organization-edit',
                        params: { id: organizationId },
                      }"
                      variant="primary"
                    >
                      {{ $t("common.actions.update") }}
                    </b-button>
                    <b-button
                      :to="{
                        name: 'billing-list',
                      }"
                      variant="primary"
                    >
                      {{ $t("invoice.title") }}
                    </b-button>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col cols="12" md="6">
              <table class="mt-2 mt-xl-0 w-100">
                <tr>
                  <th class="pb-50">
                    <feather-icon icon="UserIcon" class="mr-75" />
                    <span class="font-weight-bold">{{
                      $t("fields.phone")
                    }}</span>
                  </th>
                  <td class="pb-50">
                    {{ organization.phone }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon icon="CheckIcon" class="mr-75" />
                    <span class="font-weight-bold">{{
                      $t("fields.address")
                    }}</span>
                  </th>
                </tr>
              </table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col>
        <plan-card />
      </b-col>
    </b-row>
  </div>
  <spinner v-else />
</template>

<script>
import { show } from "@/api/organizations.api.js";
import GoBack from "@/components/Common/GoBack.vue";
import Spinner from "@/components/Spinner.vue";
import PlanCard from "./components/PlanCard.vue";
import Invoices from "../billing/List.vue";

export default {
  components: { Spinner, GoBack, PlanCard, Invoices },
  name: "organization-show",
  data() {
    return {
      loading: false,
      organization: {
        name: "",
        email: "",
        phone: null,
        address: null,
        avatar: null,
      },
    };
  },
  computed: {
    organizationId() {
      return this.$route.params?.id;
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      const { data } = await show(this.organizationId);

      Object.keys(this.organization).forEach((key) => {
        this.organization[key] = data[key];
      });

      this.loading = false;
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style>
</style>