<template>
  <b-card no-body class="border-primary mb-0">
    <spinner style="max-height: 16rem" v-if="loading" />
    <div class="d-flex flex-column" style="height: 16rem" v-else>
      <template v-if="plan">
        <b-card-header
          class="d-flex justify-content-between align-items-center pt-75 pb-25"
        >
          <h5 class="mb-0">{{ $t("subscription.current-plan") }}</h5>
          <b-badge variant="light-primary"> {{ plan.title }} </b-badge>
          <small class="text-muted w-100">{{
            $d(new Date(plan.period_ends_at * 1000), "long", "en")
          }}</small>
        </b-card-header>

        <div class="mx-2 mb-2 d-flex flex-column" style="height: 100%">
          <div class="flex-grow-1">
            <ul class="list-unstyled my-1">
              <li v-for="feature in plan.features" :key="feature.title">
                <span>{{ feature.title }}</span>
              </li>
            </ul>
          </div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
            :to="{ name: 'pricing' }"
          >
            {{ $t("subscription.upgrade") }}
          </b-button>
        </div>
      </template>
      <b-card-body style="display: grid; place-items: center" v-else>
        <p><strong>No Active Plan</strong></p>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          block
          :to="{ name: 'pricing' }"
        >
          {{ $t("subscription.no-sub-cta-button") }}
        </b-button>
      </b-card-body>
    </div>
  </b-card>
</template>

<script>
import { info } from "@/api/subscriptions.api";
import Spinner from "@/components/Spinner.vue";

export default {
  components: { Spinner },
  data() {
    return {
      loading: true,
      plan: null,
    };
  },
  methods: {
    async fetchData() {
      this.loading = true;
      const { data, error } = await info();

      if (error) {
        this.plan = null;
      } else {
        this.plan = data;
      }
      this.loading = false;
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style>
</style>